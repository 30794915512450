import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "gatsby";
const GlobalStyles = createGlobalStyle`
    body{
        font-family: Roboto, sans-serif;
        font-size: 100%;
        color: #555;
        font-weight: 300;
        background-color: #efefef;
        a{
            color: #195594;
        }
    }
`;
const StyledHome = styled.div`
  width: 320px;
  padding: 0 10px;
  margin: 0 auto;
  h1 {
    font-size: 34px;
    font-weight: 300;
    text-align: center;
  }
`;
const StyledHomeGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 140px);
  margin: 0 auto;
  gap: 20px;
  a {
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-decoration: none;
    border-radius: 25px;
    &:first-child {
      background-color: #195594;
    }
    &:nth-child(2) {
      background-color: #266d78;
    }
    &:hover {
      filter: brightness(0.5);
    }
  }
`;

const StyledFooter = styled.footer`
  text-align: center;
  margin: 30px 10px;
  width: 100%;
`;
const Index = () => {
  return (
    <>
      <GlobalStyles />
      <StyledHome>
        <h1>Gatsby Themes by Adam Webster</h1>
        <StyledHomeGrid>
          <Link to="/tabini">Tabini</Link>

          <Link to="/stori">Stori</Link>
        </StyledHomeGrid>
        <StyledFooter>
          &copy; 2020{" "}
          <a href="https://adamwebster.me/" rel="noopener">
            Adam Webster
          </a>
        </StyledFooter>
      </StyledHome>
    </>
  );
};

export default Index;
